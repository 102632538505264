import {
    footerHeightMobile,
    footerHeightDesktop,
    headerHeightMobile,
    headerHeightDesktop,
    borderWidthDesktop,
    colors,
} from './styleguide';
import { desktop } from './responsive';
import styled from '@emotion/styled';
import { scrollContainer } from './scrollcontainer';

const detailPageContainer = type => [
    {
        bottom: footerHeightMobile,
        top: headerHeightMobile,
        width: '100%',
        background: colors.white,
    },
    desktop([
        scrollContainer,
        {
            position: 'fixed',
            bottom: footerHeightDesktop,
            top: headerHeightDesktop,
            overflowY: 'scroll',
            width:
                type === 'look' ? `calc(50% - ${borderWidthDesktop}px)` : '50%',
        },
    ]),
];

export const DetailPageContainerRead = styled.div([
    detailPageContainer('read'),
    { right: 0 },
]);

export const DetailPageContainerLook = styled.div([
    detailPageContainer('look'),
    { left: 0 },
]);
