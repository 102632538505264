import React from 'react';
import { typo, rem, spacing } from './styleguide';
import { desktop } from './responsive';

export const Title = ({ title }) => (
    <h2
        css={[
            typo.contentTitle,
            {
                padding: `${rem(10)} ${spacing.contentTextSpacingMobile}`,
                textAlign: 'center',
            },
            desktop({
                display: 'none',
            }),
        ]}
    >
        {title}
    </h2>
);
