import React from 'react';
import { graphql } from 'gatsby';
import { DetailPageContainerRead } from '../components/detailPage';
import { MarkdownText } from '../components/markdown';
import * as Content from '../components/content';

const Page = ({ data }) => {
    return (
        <DetailPageContainerRead>
            <Content.Title title={data.craftJson.title} />
            <MarkdownText text={data.craftJson.read.text} />
        </DetailPageContainerRead>
    );
};

export const query = graphql`
    query($slug: String!) {
        craftJson(fields: { slug: { eq: $slug } }) {
            fields {
                slug
            }
            id
            title
            read {
                text
            }
        }
    }
`;

export default Page;
